.black-text {
    color: $primary-black;
}

.grey-text {
   color: #636569;
}

.orange-text {
    color: $primary-orange;
}

.white-text {
    color: white;
}