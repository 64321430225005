/* You can add global styles to this file, and also import other style files */
@import './styles/theme/theme.scss';
@import './styles/variables/variables.scss';
@import './styles/utility/utility.scss';
@import './styles/utility/text.scss';
@import './styles/utility/buttons.scss';

* {
  font-family: 'Open Sans', sans-serif;
}

html, body {
  font-size: calc(1rem - 1px);
}

body {
  padding-right: 0 !important;
}

/* TABLES */
  .table-wrapper {
    width: 97%;
    margin: 18px;
    display: block;
    overflow-x: auto;
  }
//table every other
  // .mat-mdc-row:nth-child(even){
  //   background-color: $light-grey !important;
  // }
/* END TABLES */

/* TYPOGRAPHY */
//modal headers
  h1 {
    color: black;
    font-size: 18px;
    margin-top: 0;
    font-weight: normal;
    padding: 30px 20px 0px 20px;
    text-align: center;
  }

//User profile checklist details header
//sub tab headers
  .bold-heading{
    padding: 30px;
    font-size: 18px;
    font-weight: bold;
  }

//user profile header/ onboarding profile header
  .item-header{
    font-size: 28px;
    font-weight: bold;
  }

//filter form field headers
  h2 {
    color: black;
    font-size: 15px;
    margin-top: 0;
    font-weight: bold;
    margin-bottom: 5px;
  }

//small headers for things like checklist detail
  small {
    color: $burnt-orange;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    padding: 5%;
  }
/* END TYPOGRAPHY */

/* FORM FIELDS */
//any drop down field needs padding
  .mat-mdc-form-field {
    display: block !important;
    padding-bottom: 20px;
    padding-top: 5px;
  }

//Fix for the mat form field labels being cut off
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above{
    padding-right: 10px;
    background-color: white !important;
  }

  .mdc-text-field {
    background-color: rgba(0, 0, 0, 0) !important;
  }

//fix for dropdown selections being cut off
  .mat-mdc-select-value{
    overflow: visible !important;
  }

//form fields have regular letter spacing
  .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control{
    letter-spacing: normal !important;
  }
  .mat-mdc-dialog-container .mdc-dialog__content{
    letter-spacing: 0px !important;
  }
/* END FORM FIELDS */

/* BUTTONS */
//for buttons on modals
  button {
    text-transform: uppercase;
  }

//button groups on modals
  .action-buttons{
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 20px 0px 20px 0px;
  }

//regular orange buttons
  .action {
    background-color: $primary-orange !important;
    color: $white !important;
  }

//for button groups left aligned
  .action-buttons-left{
    display: flex;
    gap: 10px;
    justify-content: left;
    padding: 15px;
  }

//toggle groups for filters
  .mat-button-toggle-group {
    margin-bottom: 20px;
    margin-top: 5px;
  }

//for checklist tasks
  .delete-button {
    mat-icon {
      color: $dark-grey !important;
    }
    position: absolute;
    right: 10px;
    align-items: center;
    color: $primary-orange;
    border: none;
    font-weight: bolder;
    justify-content: center;
  }

// qualifications buttons
//white background, orange text RAISED
  .mat-raised-button{
    margin: 0 2px !important;
    line-height: 26px !important;
    padding: 0 8px !important;
    font-size: 12px !important;
    color: $primary-orange;
    justify-content: in;
  }

// orange background, white text
  .squarish-button {
    padding: 6px 12px;
    align-items: center;
    border-radius: 10px;
    background: $primary-orange !important;
    border: 1px solid $primary-orange;
    color: white !important;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    justify-content: center;

    &.outlined {
      // white background, orange text
      background-color: transparent !important;
      color: $primary-orange !important;
    }
  }

  button.mat-accent {
    color: #fff;
  }

//app-buttons are the orange buttons on the main pages of the app
//make sure the icons within them are white
  .app-button mat-icon{
    color:white;
  }

//style buttons when they are disabled x
  .mat-mdc-button[disabled] {
    background-color: $text-grey !important;
    color: $dark-grey !important;
  }

//icon buttons
  .mdc-icon-button{
    padding: 0px !important;
  }

//Back button class
  .breadcrumb-row {
    display: flex;
    align-content: center;
    width: 100%;
    height: 48px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 34px;

    mat-icon {
      color: $text-grey;
      margin: 12px 4px 12px 12px;
    }

    a {
      color: $text-grey;
      margin: 14px 0;
      font-size: 14px;
      cursor: pointer;
    }

    &:hover {
      mat-icon {
        color: $primary-orange;
      }
      a {
        color: $primary-orange;
      }
    }
  }
/* END BUTTONS */

/* HOVER EFFECTS */
//table hover colors
.table-container table tr:not(.mat-header-row):hover {
  background-color: $lighter-grey;
}
//make sure header row doesn't have hover effect
.mat-mdc-table .mdc-data-table__header-row{
  background-color: white !important;
}
//all icons are clickable
mat-icon{
  cursor: pointer;
}
/* END HOVER EFFECTS */

/* NAV BARS */
//Grey line under nav bars
  .mat-mdc-tab-header{
    border-bottom: 1px solid rgba(0, 0, 0, .12);
  }
//tab widths
  .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab{
    flex-grow: 0;
    max-width: 160px;
  }
//tab width for qualifications
  .mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs .mat-mdc-tab-link{
    flex-grow: 0;
    max-width: 160px;
  }
//active tab is orange
  .tabs-orange-highlight{
    .mdc-tab-indicator__content--underline {
      --mdc-tab-indicator-active-indicator-color:  #ff6600;
    }
    .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
      color: $primary-orange;
    }
  }
//active tab text orange for qualifications
  .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label{
    color: $primary-orange;
  }
/* END NAV BARS */

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark{
  color:white !important;
}

.orange {
  color: $primary-orange;
}

.mat-tree-node{
  word-wrap: none !important;
}

//mat-paginator
.mat-mdc-paginator{
  background-color: inherit;
}

.mdc-data-table__header-cell{
  font-weight: 500;
  color: #0000008a;
  font-size: 12px;
}

.material-icons{
  font-size: 24px;
  padding-left: 6px;
  color: $dark-grey;
}

.mat-mdc-form-field .material-icons{
  font-size: 18px;
  padding-left: 6px;
  padding-top: 10px;
  color: $dark-grey;
}

//for filter headers
.filters-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  button:focus {
    outline: 0;
  }
}

// fix height of mat-sidebar
.mat-drawer-container {
  height: 100vh;
  display: flex;
}
.mat-drawer-content {
  display: flex;
}

.card {
  margin: 20px;
}

.flex {
  display: flex;
}

.stack {
  display: flex;
  flex-direction: column;

  &.spacing-2 > * {
    margin-bottom: 16px;
  }

  &.row {
    flex-direction: row;
    &.spacing-2 > * {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
}

.spark-tools-menu-wrapper {
  position: absolute;
  top: 25px;
  right: 25px;

  #spark-tools-menu {
    position: absolute;
    inset: unset;
    bottom: 25px;
    right: 25px;
    margin-top: 10px;
    padding: 15px 20px;
    border: 2px solid orange;
    border-radius: 15px;

    button {
      text-transform: none;
    }
  }
}
