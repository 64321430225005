.icon-button {
  margin: 6px;
  padding: 6px;
  display: inline-flex;
  align-items: center;
  color: $dark-grey;
  border: none;
  background-color: transparent;

  &:disabled {
    opacity: 0.6;

    &:hover {
      color: #636569;
    }
  }

  mat-icon {
    margin-right: 6px;
  }
}

.icon-button:hover {
  color: $primary-orange;
  cursor: pointer;
}
