h1 {
  font-family: 'Open Sans', sans-serif;
  font-size: 28px;
}

h3 {
  color: $text-grey;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}

h4 {
  color: $text-grey;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
}

h5 {
  color: $black;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}

h6 {
  color: $dark-grey;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
}

p {
  font-size: 18px;
}

li {
  color: $black;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  margin-top: 0px;
}

// Text-align
.a-ctr {
  text-align: center;
}

// Font size
.t-14 {
  font-size: 14px;
}

// Margin
.mt-14 {
  margin-top: 14px;
}
.mt-18 {
  margin-top: 18px;
}

.mt-28 {
  margin-top: 28px;
}

.mb-28 {
  margin-bottom: 28px;
}
