.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-start {
  display: flex;
  justify-content: flex-start;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.justify-around {
  display: flex;
  justify-content: space-around;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.align-center {
  display: flex;
  align-items: center;
}

.align-start {
  display: flex;
  align-items: flex-start;
}

.align-end {
  display: flex;
  align-items: flex-end;
}

.align-around {
  display: flex;
  align-items: space-around;
}

.align-between {
  display: flex;
  align-items: space-between;
}

.self-center {
  align-self: center;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.no-shrink {
  flex-shrink: 0;
}
