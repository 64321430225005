$black: #000000;
$burnt-orange: #B77700;
$dark-grey: #636569;
$light-grey: #E6E6E6;
$lighter-grey: #f4f4f4;
$primary-black: #37383B;
$primary-orange: #FF6900;
$light-orange: #FFF0E6;
$text-grey: #9E9E9E;
$white: #ffffff;
$status-green: #3CC13B;
$status-green-10: #ECF9EB;
$status-blue: #3E66FB;
$status-blue-10: #ECF0FF;
$status-gold: #F3BB1C;
$status-gold-10: #FEF8E8;
