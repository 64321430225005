.full-width {
  width: 100%;
}

.d-flex {
  display: flex;
}

.content-end {
  justify-content: flex-end;
}

.text-align-right {
  text-align: right !important;
}
